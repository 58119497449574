import { z } from "zod";

export type ChatRequest = {
  chat_history: Array<ChatMessage>;
  question: string;
  chat_id: string; // guid
};

type ChatMessageAbstract = {
  content: string;
  error: boolean;
};

export type ChatMessageAssistant = ChatMessageAbstract & {
  role: "assistant";
  questionId?: string;
  questionTimestamp?: string;
};

export type ChatMessageUser = ChatMessageAbstract & {
  role: "user";
};

export type ChatMessage = ChatMessageAssistant | ChatMessageUser;

export const ChatResponseSchema = z.object({
  answer: z.string(),
  question_id: z.string(),
  question_timestamp: z.string(),
});

export const RephraseResponseSchema = z.object({
  rephrased_prompt: z.string(),
});
