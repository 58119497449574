import { useCallback, useState } from "react";

export const useUserQuestion = () => {
  const [rephraseHistory, setRephraseHistory] = useState<string[]>([""]);

  const pushRephraseHistory = useCallback(
    (message: string) =>
      setRephraseHistory((prevHistory) => {
        return [...prevHistory, message];
      }),
    [setRephraseHistory],
  );

  const revertLastRephrase = useCallback(() => {
    setRephraseHistory((prevHistory) =>
      prevHistory.length === 1 ? [...prevHistory] : prevHistory.slice(0, -1),
    );
  }, [setRephraseHistory]);

  const setUserQuestion = useCallback(
    (message: string) => {
      setRephraseHistory((prevHistory) => [
        ...prevHistory.slice(0, prevHistory.length - 1),
        message,
      ]);
    },
    [setRephraseHistory],
  );

  const clearQuestionContext = useCallback(
    () => setRephraseHistory([""]),
    [setRephraseHistory],
  );

  return {
    userQuestion: rephraseHistory[rephraseHistory.length - 1],
    setUserQuestion,
    clearQuestionContext,
    rephrase: {
      push: pushRephraseHistory,
      revert: revertLastRephrase,
      canRevert: rephraseHistory.length > 1,
    },
  };
};
